/* App.css or Login.css */

/* Background styling */
body {
    margin: 0;
    font-family: 'CircularStd-Bold', sans-serif;
    background: white; /* Plain white background */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Logo styling */
  .logo {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 1em;
  }

/* Login Image styling */
.login-image {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: -1;
    width: 500px; /* Adjust size as needed */
  }
  
/* Login container styling */
.login-container {
    /* align right */
    width: 450px;
    text-align: left;
    padding: 2em;
    background: rgba(255,255,255,1);
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    margin: 50px auto;
  }

  .login-txt{
    position: absolute;
    top: 49%;
    left: 39%;
  }
  
  /* Button styling */
  .login-btn {
    background-color: #00342C; /* Specific green color */
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 40px;
    height: 40px;
    width: 465px;
  }
  
  .login-btn:hover {
    background-color: #033a25; /* Darker green on hover */
  }
  
  /* Input styling */
  .input-field {
    width: 100%;
    padding: 0.5em;
    margin: 0.5em 0;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  /* Other texts */
  .sub-text {
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  
  