/* CSS for Dashboard.tsx */

/* Style for the container */
.full-height-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #ffffff;
  padding: 20px;
}

/* Style for the heading */
.h1 {
  position: absolute;
  font-size: 28px;
  color: #00342C;
  text-align: left;
  left: 100px;
  top: 58px;
}

.Ph1 {
  position: absolute;
  font-size: 28px;
  color: #00342C;
  text-align: left;
  left: 95px;
  top: 65px;
}

/* Style for the user's name input */
.user-input {
  width: 100%;
  max-width: 400px; /* Adjust the input width as needed */
  margin-top: 30px;
}

.user-input li:hover{
  border-color: #00342C;
}

/* Style for the list of procedures */
.procedure-list {
  list-style: none;
  margin: 0;
  position: relative;
  left: -10px;
  top: 40px;
  padding: 20px;
  text-align: center;  
}

.procedure-list li {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 25px;
  padding: 8px 0;
  transition: background-color 0.1s;
  border: 3px solid #00342C;
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 250px;
  background-color: #00342C;
}

.procedure-list li:hover {
  border: 3px solid #105a44;
  background-color: #105a44;
}

/* Style for the parameter labels */
.parameter-label {
  text-align: right;
  font-weight: bold;
  margin-right: 50px; /* Adjust the right margin as needed */
}

/* Style for the input fields */
.parameter-input {
  width: 100%;
  max-width: 300px; /* Adjust the input width as needed */
  margin-bottom: 50px; /* Add spacing between input fields */
  align-content: center;
}

/* Style for the Verify Information button */
.verify-button {
  width: 100%;
  max-width: 300px; /* Adjust the button width as needed */
}

.ret2Dash{
  position: absolute;
  right: 50px;
  top: 150px;
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #00342C;
  background-image: linear-gradient(45deg, #00684A 0%, #00342C 51%, #00684A 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ret2Dash:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #FFF;
  text-decoration: none;
}

.ret2Dash:active {
  transform: scale(0.95);
}

.ProcDash{
  position: absolute;
  right: 50px;
  top: 120px;
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #00342C;
  background-image: linear-gradient(45deg, #00684A 0%, #00342C 51%, #00684A 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ProcDash:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #FFF;
  text-decoration: none;
}

.ProcDash:active {
  transform: scale(0.95);
}
/* Additional styles for other elements as needed */

.options-list {  
  position: absolute;
  top: 12em;
  left: 2em;
}
