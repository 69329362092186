body {
    font-family: Arial, sans-serif;
    background-size: cover;
    height: 100vh;
  }
  
  h1 {
    text-align: center;
    font-family: Tahoma, Arial, sans-serif;
    color: #06D85F;
    margin: 80px 0;
  }
  
  .box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255,255,255,0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
  }
  
  .button2 {
    position: absolute;
    font-size: 1em;
    padding: 5px;
    color: #fff;
    width: 100px;
    left: 130px;
    background-color: #00342C;
    border: 2px solid #00342C;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }

  .button2:hover {
    background-color: #0f6649;
    border: 2px solid #0f6649;
    border-radius: 10px;
  }

  .button1 {
    font-size: 1em;
    padding: 5px;
    color: #fff;
    width: 100px;
    background-color: #00342C;
    border: 2px solid #00342C;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }
  .button1:hover {
    background-color: #0f6649;
    border: 2px solid #0f6649;
    border-radius: 10px;
  }

  .button {
    font-size: 1em;
    padding: 5px;
    color: #fff;
    width: 200px;
    background-color: #00342C;
    border: 2px solid #00342C;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }
  .button:hover {
    background-color: #0f6649;
    border: 2px solid #0f6649;
    border-radius: 10px;
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 0;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h2 {
    margin-top: 0;
    color: black;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    color: #ccc;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  @media screen and (max-width: 700px){
    .box{
      width: 70%;
    }
    .popup{
      width: 70%;
    }
  }