/* Navbar.css */
.navbar {
  position: absolute;
  top: 9em;
  left: 0em;
}
  
.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #00342C;
}

.nav-menu {
  z-index: 100;
  background-color: gray;
  height: 100vh;
  display: flex;
  justify-content: center;
  border: 3px solid black;
  border-color: black;
  margin-top: -88px;
  margin-left: -300%;
  transition: 850ms;
}

.nav-menu.active {
  margin-top: -15px;
  margin-left: -87%;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: gray;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}
  