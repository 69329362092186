.header{
  position: inherit;
    color: #00342C;
    margin-top: 10px;
    margin-left: -30px;
}
  
h3{
    color: #00342C;
    margin-top: -55px;
    margin-left: -30px;

}
  
/* Style for the form group (each line) */
.form-group {
  display: absolute;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px; /* Increase or decrease spacing between lines as needed */
  width: 600px;
}

  
  /* Style for the labels on the left */
  .form-group label {
    flex-basis: 40%; /* Adjust the width of the labels */
    text-align: left;
    font-weight: bold;
    font-size: 1rem;
  }
  
  /* Style for the input fields on the right */
  .form-group input[type="text"] {
    flex-basis: 55%; /* Adjust the width of the input fields */
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 40%;
    margin-top: -30px;
    margin-left: 30px;
    float: right;
    padding: 10px 12px;
    font-size: 1rem;
  }
  /* lets create some styling for the date-picker */
  .form-group input[type="datetime"] {
    flex-basis: 55%; /* Adjust the width of the input fields */
    border: 1px solid #ccc;
    border-radius: 4px;
    max-width: 40%;
    margin-top: -30px;
    margin-left: 30px;
    float: right;
    padding: 10px 12px;
    font-size: 1rem;
  }

  .comment-textarea {
    flex-basis: 55%; /* Adjust the width of the textarea */
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: -10px;
    margin-right: 45px;
    height: 75px; /* Adjust height as needed */
    width: 195px;
    text-align: top;
    overflow-wrap: break-word;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 30px;
    float: right;
    padding: 10px 12px;
    font-size: 1rem;
    resize: vertical; /* Allow vertical resizing, remove if not needed */
  }
  
  /* Style for the button */
  .verInfobutton {
    width: 37%;
    padding: 7px 0;
    font-size: 1rem;
    text-indent: 3em;
    float: left;
    background-color: #00342C;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    color: #ffffff;
    margin-top: 57px; /* Increase spacing below the form */
  }

  .verInfobutton:hover {
    background-color: #0f6649;
    border: 0px solid #0f6649;
    border-radius: 10px;
  }

  .retProcDashboardButton.p-button.p-component {
    width: 41%;
    padding: 7px 0;
    font-size: 1rem;
    text-indent: 3em;
    float: right;
    border: none;
    border-radius: 10px;
    margin-top: 27px;
    background-color: #00342C;
    cursor: pointer;
    color: #ffffff; /* Increase spacing below the form */
    text-align: center;
  }

  .retProcDashboardButton:hover {
    background-color: #0f6649;
    border: 0px solid #0f6649;
    border-radius: 10px;
  }
  
  .cal-icon{
    width: 20px;
    height: 20px;
    padding: 0px 0;
    font-size: 1rem;
    margin-right: -240px;
    border: none;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
    margin-top: -20px; /* Increase spacing below the form */
    float: right;
  }

  .radio-button-true{
    width: 20px;
    height: 20px;
    padding: 0px 0;
    font-size: 1rem;
    margin-right: 180px;
    border: none;
    cursor: pointer;
    margin-top: -2px; /* Increase spacing below the form */
    float: right;
  }

  .radio-button-false{
    width: 20px;
    height: 20px;
    padding: 0px 0;
    font-size: 1rem;
    margin-right: -140px;
    border: none;
    cursor: pointer;
    margin-top: -2px; /* Increase spacing below the form */
    float: right;
  }

  .radio-button-label-true{
    width: 20px;
    height: 20px;
    padding: 0px 0;
    font-size: 1rem;
    margin-right: -18px;
    border: none;
    cursor: pointer;
    margin-top: -25px; /* Increase spacing below the form */
    float: right;
  }

  .radio-button-label-false{
    width: 20px;
    height: 20px;
    padding: 0px 0;
    font-size: 1rem;
    margin-right: -132px;
    border: none;
    cursor: pointer;
    margin-top: -25px; /* Increase spacing below the form */
    float: right;
  }

  .FadeLoaderProcedureDetails {    
    position: absolute;
    left: 60em;
    top: 30em;
}
  /* ... Rest of your styles ... */