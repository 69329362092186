.form-style {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 400px;
    margin-right: 00px;
    margin-top: 20px;
    color: #00342C;
}

.FadeLoader {    
    position: relative;
    left: 50em;
    top: 20em;
}


.option {
    flex-direction: row;
    margin-bottom: 20px;
    
}

.li {
    z-index: 1;
    color: black;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s;
}

.search-box {
  position: absolute;  
  cursor:text;    
  padding: 0px 8px;
  width: 500px;
  height: 30px;
  border-radius: 8px;
  font-size: 17px;
}

.my-search-input .p-inputtext .p-component {
    width: 100%;
    height: 40px;
    font-size: 1.25rem;
    padding-left: 44px;
    border: 2px solid #00342C;
    border-radius: 10px;
  }

  /* styles.css */
  .custom-input-text .p-inputtext {
    width: 100% !important;
    margin-left: 44px;
    height: 40px; 
    font-size: 1.25rem; 
    padding-left: 20px; 
    border: 2px solid #00342C !important; 
    border-radius: 10px !important;
    /* Additional styles */
  }

  .custom-input-text .p-inputtext:focus{
    width: 100% !important;
    margin-left: 44px;
    height: 40px; 
    font-size: 1.25rem; 
    padding-left: 20px; 
    box-shadow: 0 0 5px rgba(81, 203, 238, 1); /* Optional: add a glow effect */
  }  
